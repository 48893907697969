import FuseUtils from '@fuse/utils'
import appsConfigs from 'app/main/apps/appsConfigs'
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs'
import LoginConfig from 'app/main/login/LoginConfig'
import LogoutConfig from 'app/main/logout/LogoutConfig'
import TerminalConfig from 'app/main/terminal/TerminalConfig'
import RegisterConfig from 'app/main/register/RegisterConfig'
import React from 'react'
import { Redirect } from 'react-router-dom'

const routeConfigs = [
  ...appsConfigs,
  ...authRoleExamplesConfigs,
  LogoutConfig,
  LoginConfig,
  RegisterConfig,
  TerminalConfig,
  LogoutConfig
]

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin', 'staff', 'user']),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/apps/dashboards/analytics" />
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />
  }
]

export default routes
