import auth from 'app/auth/store/reducers'
import { combineReducers } from 'redux'
import fuse from './fuse'
import next from './next'

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    fuse,
    next,
    ...asyncReducers
  })

export default createReducer
