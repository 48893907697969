import axios from 'axios'
import { get } from 'lodash'
import { apiConfig } from './apiConfig'

class apiService {
  static call = async options => {
    const { id = '', serviceName, entity = '', data = null, params = null, method = 'get', action = 'get' } = options

    const apiFunc = get(
      apiConfig,
      [serviceName, entity, action].filter(e => e)
    )
    const url = apiConfig.url() + apiFunc(id)

    let result
    try {
      result = await axios({
        url,
        data,
        method,
        params
      })
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.reasons) {
        throw error.response.data.reasons
      } else {
        throw error
      }
    }
    const statuses = [200, 201]
    if (statuses.includes(result.status)) {
      return result.data
    }
    throw result
  }
}

export default apiService
