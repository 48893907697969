import * as Actions from 'app/store/actions/next'

const initialState = {
  settings: {
    btw: [],
    groups: [],
    branches: [],
    itemTypes: [],
    groupTypes: [],
    departments: [],
    paymentTypes: [],
    deliveryTypes: [],
    discountTypes: []
  },
  user: {
    uuid: 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1',
    from: 'custom-db',
    role: 'admin',
    data: {
      displayName: 'Andrew Shapovalov',
      photoURL: 'assets/images/logos/fak.svg',
      email: 'admin',
      settings: {
        layout: {
          style: 'layout1',
          config: {
            scroll: 'content',
            navbar: { display: true, folded: true, position: 'left' },

            toolbar: {
              display: true,
              style: 'fixed',
              position: 'below'
            },

            footer: {
              display: true,
              style: 'fixed',
              position: 'below'
            },

            mode: 'fullwidth'
          }
        },
        customScrollbars: true,
        theme: { main: 'defaultDark', navbar: 'defaultDark', toolbar: 'defaultDark', footer: 'defaultDark' }
      },

      shortcuts: ['calendar', 'mail', 'contacts']
    },
    backoffice: {
      username: null,
      password: null
    }
  }
}

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_FAK_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      }
    }

    case Actions.SET_FAK_USER_SETTINGS: {
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      }
    }

    default: {
      return state
    }
  }
}

export default dialog
