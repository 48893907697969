import React from 'react'
import { Router } from 'react-router-dom'

import rtl from 'jss-rtl'
import { create } from 'jss'
import history from '@history'
import MomentUtils from '@date-io/moment'
import jssExtend from 'jss-plugin-extend'
import FuseTheme from '@fuse/core/FuseTheme'
import FuseLayout from '@fuse/core/FuseLayout'
import Provider from 'react-redux/es/components/Provider'
import FuseAuthorization from '@fuse/core/FuseAuthorization'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles'
import routes from './fuse-configs/routesConfig'
import AppContext from './AppContext'
import { Auth } from './auth'
import store from './store'

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById('jss-insertion-point')
})

const generateClassName = createGenerateClassName()

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes
      }}
    >
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Auth>
              <Router history={history}>
                <FuseAuthorization>
                  <FuseTheme>
                    <FuseLayout />
                  </FuseTheme>
                </FuseAuthorization>
              </Router>
            </Auth>
          </MuiPickersUtilsProvider>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  )
}

export default App
