import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
// import CircularProgress from '@material-ui/core/CircularProgress'
import { useLocation } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText
  },
  iframe: {
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  loader: {
    top: '50%',
    left: '50%',
    position: 'absolute'
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function Terminal(props) {
  const classes = useStyles()
  const location = useLocation()
  const [terminalUrl, setTerminalUrl] = useState(null)

  const fetchData = async () => {
    // navigator.serviceWorker.register('./Terminal.js')
    localStorage.setItem('terminalMode', true)
    // Only for demonstration in development mode.
    // if (window.location.href !== 'http://localhost:3000/terminal') {
    setTimeout(() => {
      setTerminalUrl('http://fak-terminal.local.com/')
    }, 3000)

    // } else {
    const result = location.state
    console.log('fetchData -> result', result)
    // window.open(result.terminalHostApi)
    // window.location.replace(result.terminalHostApi)
    // setTerminalUrl(result.terminalHostApi)
    // }
  }

  useEffect(() => {
    fetchData()
  })

  const terminalMode = () => {
    localStorage.setItem('terminalMode', false)
    props.history.push('/')
  }

  // if (!terminalUrl) {
  //   return (
  //     <div className={classes.loader}>
  //       <CircularProgress color="secondary" />
  //     </div>
  //   )
  // }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.closeButton}>
          <IconButton
            aria-label="close terminal"
            component="span"
            onClick={() => {
              terminalMode()
            }}
          >
            <Icon>cancel</Icon>
          </IconButton>
        </div>
      </div>

      <div>Terminal mode started</div>
      {terminalUrl && (
        <div>
          <p>terminal logic check</p>
        </div>
      )}
    </>
  )
}
export default Terminal
