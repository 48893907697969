import i18next from 'i18next'
import ar from './navigation-i18n/ar'
import en from './navigation-i18n/en'
import tr from './navigation-i18n/tr'

i18next.addResourceBundle('en', 'navigation', en)
i18next.addResourceBundle('tr', 'navigation', tr)
i18next.addResourceBundle('ar', 'navigation', ar)

const navigationConfig = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'dashboards',
        title: 'Dashboards',
        translate: 'DASHBOARDS',
        type: 'collapse',
        icon: 'dashboard',
        children: [
          {
            id: 'analytics-dashboard',
            title: 'Analytics',
            type: 'item',
            url: '/apps/dashboards/analytics'
          }
        ]
      },
      {
        id: 'e-commerce',
        title: 'BackOffice',
        translate: 'ECOMMERCE',
        type: 'collapse',
        icon: 'shopping_cart',
        url: '/apps/e-commerce',
        children: [
          {
            id: 'e-commerce-orders',
            title: 'Orders',
            type: 'item',
            url: '/apps/e-commerce/orders',
            exact: true
          },
          {
            id: 'e-commerce-products',
            title: 'Products',
            type: 'item',
            url: '/apps/e-commerce/products',
            exact: true
          },
          {
            id: 'e-commerce-product-groups',
            title: 'Product Groups',
            type: 'item',
            url: '/apps/e-commerce/product-groups',
            exact: true
          },
          {
            id: 'e-commerce-customers',
            title: 'Customers',
            type: 'item',
            url: '/apps/e-commerce/customers',
            exact: true
          }
        ]
      }
    ]
  },
  {
    type: 'divider',
    id: 'divider-2'
  }
]

export default navigationConfig
