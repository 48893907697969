import FuseSplashScreen from '@fuse/core/FuseSplashScreen'
import * as userActions from 'app/auth/store/actions'
import jwtService from 'app/services/jwtService'
import * as Actions from 'app/store/actions'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class Auth extends Component {
  state = {
    waitAuthCheck: true
  }

  componentDidMount() {
    return this.jwtCheck().then(() => {
      this.setState({ waitAuthCheck: false })
    })
  }

  jwtCheck = () =>
    new Promise(resolve => {
      jwtService.on('onAutoLogin', () => {
        this.props.showMessage({ message: 'Logging in with JWT' })

        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .getUser()
          .then(user => {
            this.props.setUserData(user)
            this.props.showMessage({ message: 'Successfully logged in' })

            resolve()
          })
          .catch(error => {
            this.props.showMessage({ message: error })

            resolve()
          })
      })

      jwtService.on('onAutoLogout', message => {
        if (message) {
          this.props.showMessage({ message })
        }

        this.props.logout()

        resolve()
      })

      jwtService.on('onNoAccessToken', () => {
        resolve()
      })

      jwtService.init()

      return Promise.resolve()
    })

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
      getFakSettings: Actions.getSettings,
      getFakUserSettings: Actions.getUserSettings
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(Auth)
