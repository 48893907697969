const apiUrl = process.env.REACT_APP_API_URL
const apiPort = process.env.REACT_APP_API_PORT

export const apiConfig = {
  url: () => `${apiUrl}:${apiPort}/api/v1/`,
  orderReport: {
    today: () => 'orders/report/today',
    week: () => 'orders/report/week',
    month: () => 'orders/report/month',
    history: () => 'orders/report/history',
    sales: () => 'orders/report/sales',
    kpi: () => 'orders/report/kpi'
  },
  products: {
    list: () => 'products'
  },
  product: {
    create: () => `products`,
    generatePlu: () => `products/plu`,
    get: id => `products/${id}`,
    save: id => `products/${id}`,
    checkCode: () => 'products/check/code'
  },
  productGroups: {
    list: () => 'groups'
  },
  customer: {
    get: id => `customers/${id}`
  },
  orders: {
    list: () => 'orders'
  },
  order: {
    get: id => `orders/${id}`
  },
  customers: {
    list: () => 'customers'
  },
  settings: {
    get: () => 'settings',
    user: () => 'settings/user',
    device: () => 'settings/terminal_info'
  }
}
export const apiStringify = moduleName => {
  return Object.keys(apiConfig[moduleName]).reduce((acc, prop) => {
    acc[prop] = prop

    return acc
  }, {})
}
