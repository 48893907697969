import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { TextFieldFormsy } from '@fuse/core/formsy'
import InputAdornment from '@material-ui/core/InputAdornment'

import Formsy from 'formsy-react'
import * as authActions from 'app/auth/store/actions'

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function JWTLogin() {
  const dispatch = useDispatch()
  const login = useSelector(({ auth }) => auth.login)

  const [isFormValid, setIsFormValid] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    if (login?.error) {
      formRef.current.updateInputsWithError({
        password: login.error
      })
      disableButton()
    }
  }, [login])

  function disableButton() {
    setIsFormValid(false)
  }

  function enableButton() {
    setIsFormValid(true)
  }

  function handleSubmit(model) {
    dispatch(authActions.submitLogin(model))
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <input
          type="password"
          style={{ display: 'none' }}
          browserautocomplete="new-password"
          autoComplete="new-password"
        />
        <TextFieldFormsy
          className="mb-16"
          type="text"
          name="email"
          label="Username/Email"
          value=""
          validations={{
            minLength: 4
          }}
          validationErrors={{
            minLength: 'Min character length is 4'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  email
                </Icon>
              </InputAdornment>
            )
          }}
          variant="outlined"
          required
        />

        <TextFieldFormsy
          className="mb-16"
          autoComplete="new-password"
          type="password"
          name="password"
          label="Password"
          value=""
          validations={{
            minLength: 4
          }}
          validationErrors={{
            minLength: 'Min character length is 4'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  vpn_key
                </Icon>
              </InputAdornment>
            )
          }}
          variant="outlined"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16 normal-case"
          aria-label="LOG IN"
          disabled={!isFormValid}
          value="legacy"
        >
          Login
        </Button>
      </Formsy>
    </div>
  )
}

export default JWTLogin
