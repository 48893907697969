import Formsy from 'formsy-react'
import Button from '@material-ui/core/Button'
import { TextFieldFormsy } from '@fuse/core/formsy'
import { useDispatch, useSelector } from 'react-redux'
import * as pincodeActions from 'app/auth/store/actions'
import React, { useEffect, useRef, useState } from 'react'

function PinCodeLogin() {
  const dispatch = useDispatch()
  const pincode = useSelector(({ auth }) => auth.pincode)

  const [isFormValid, setIsFormValid] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    if (pincode.error && pincode.error.pincode) {
      formRef.current.updateInputsWithError({
        ...pincode.error
      })
      disableButton()
    }
  }, [pincode.error])

  function disableButton() {
    setIsFormValid(false)
  }

  function enableButton() {
    setIsFormValid(true)
  }

  function handleSubmit(model) {
    dispatch(pincodeActions.submitPinCodeLogin(model))
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <TextFieldFormsy
          className="mb-16"
          autoFocus
          type="password"
          name="pincode"
          label="Pincode"
          validations={{
            minLength: 8
          }}
          validationErrors={{
            minLength: 'Min character length is 8'
          }}
          inputProps={{ pattern: '[0-9]*' }}
          variant="outlined"
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16 normal-case"
          aria-label="LOG IN"
          disabled={!isFormValid}
          value="legacy"
        >
          Login
        </Button>
      </Formsy>
    </div>
  )
}

export default PinCodeLogin
