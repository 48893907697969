import pinCodeService from 'app/services/pinCodeService'
import * as UserActions from './user.actions'

export const PINCODE_LOGIN_ERROR = 'PINCODE_LOGIN_ERROR'
export const PINCODE_LOGIN_SUCCESS = 'PINCODE_LOGIN_SUCCESS'

export function submitPinCodeLogin({ pincode }) {
  return dispatch =>
    pinCodeService
      .signInWithPinCode(pincode)
      .then(user => {
        dispatch(UserActions.setUserData(user))

        return dispatch({
          type: PINCODE_LOGIN_SUCCESS
        })
      })
      .catch(error => {
        return dispatch({
          type: PINCODE_LOGIN_ERROR,
          payload: error
        })
      })
}
