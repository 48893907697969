import clsx from 'clsx'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& .logo-icon': {
      width: 24,
      height: 24,
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    },
    '& .react-badge, & .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  reactBadge: {
    backgroundColor: '#121212',
    color: '#61DAFB'
  }
}))

function Logo() {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, 'flex items-center')}>
      <img className="logo-icon" src="assets/images/logos/fak.svg" alt="logo" />
      <Typography className="text-16 mx-12 font-light logo-text" color="inherit">
        FAK Next
      </Typography>
    </div>
  )
}

export default Logo
