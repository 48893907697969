import * as Actions from '../actions'

const initialState = {
  success: false,
  error: {
    pincode: null
  }
}

const pincode = (state = initialState, action) => {
  switch (action.type) {
    case Actions.PINCODE_LOGIN_SUCCESS: {
      return {
        ...initialState,
        success: true
      }
    }
    case Actions.PINCODE_LOGIN_ERROR: {
      return {
        success: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default pincode
