import FuseUtils from '@fuse/utils/FuseUtils'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
/* eslint-disable camelcase */

import jwtServiceConfig from './jwtServiceConfig'

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors()
    this.handleAuthentication()
  }

  // @TODO get real profile from backed part, for now it is fake user data
  getUser = () => {
    return new Promise((resolve, reject) => {
      resolve({
        uuid: 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1',
        from: 'custom-db',
        role: 'admin',
        data: {
          displayName: 'Andrew Shapovalov',
          photoURL: 'assets/images/avatars/AndrewShapovalov.gif',
          email: 'admin',
          settings: {
            layout: {
              style: 'layout1',
              config: {
                scroll: 'content',
                navbar: { display: true, folded: true, position: 'left' },

                toolbar: {
                  display: true,
                  style: 'fixed',
                  position: 'below'
                },

                footer: {
                  display: true,
                  style: 'fixed',
                  position: 'below'
                },

                mode: 'fullwidth'
              }
            },
            customScrollbars: true,
            theme: { main: 'defaultDark', navbar: 'defaultDark', toolbar: 'defaultDark', footer: 'defaultDark' }
          },

          shortcuts: ['calendar', 'mail', 'contacts']
        }
      })
    })
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response
      },
      err => {
        return new Promise((resolve, reject) => {
          if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            console.dir(err)
            const message = err.response.data.message || 'Invalid access_token'
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', message)
            this.setSession(null)
          }
          throw err
        })
      }
    )
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken()
    if (!access_token) {
      this.emit('onNoAccessToken')

      return
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token)
      this.emit('onAutoLogin', true)
    } else {
      this.setSession(null)
      this.emit('onAutoLogout', 'access_token expired')
    }
  }

  createUser = data => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token)
          resolve(response.data.user)
        } else {
          reject(response.data.error)
        }
      })
    })
  }

  signInWithEmailAndPassword = (login, password) => {
    const url = jwtServiceConfig.url() + jwtServiceConfig.oauth2.authorize()

    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          login,
          password
        })
        .then(
          response => {
            if (response.data.token) {
              this.setSession(response.data.token)

              resolve(this.getUser())
            } else {
              reject(response.data.error)
            }
          },
          error => {
            reject(error.response.data.message)
          }
        )
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth/access-token', {
          data: {
            access_token: this.getAccessToken()
          }
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            this.logout()
            Promise.reject(new Error('Failed to login with token.'))
          }
        })
        .catch(error => {
          this.logout()
          Promise.reject(new Error('Failed to login with token.'))
        })
    })
  }

  updateUserData = user => {
    return axios.post('/api/auth/user/update', {
      user
    })
  }

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token)
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
    } else {
      localStorage.removeItem('jwt_access_token')
      delete axios.defaults.headers.common.Authorization
    }
  }

  logout = () => {
    this.setSession(null)
  }

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false
    }
    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn('access token expired')
      return false
    }

    return true
  }

  getAccessToken = () => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('token')) {
      const token = searchParams.get('token')
      searchParams.delete('token')

      return token
    }
    return window.localStorage.getItem('jwt_access_token')
  }
}

const instance = new JwtService()

export default instance
