const apiUrl = process.env.REACT_APP_API_URL
const apiPort = process.env.REACT_APP_API_PORT

const pincodeServiceConfig = {
  url: () => `${apiUrl}:${apiPort}/api/v1/`,
  oauth2: {
    pincode: () => 'oauth2/pincode'
  }
}

export default pincodeServiceConfig
