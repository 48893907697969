import apiService from 'app/services/apiService'
import { apiStringify } from 'app/services/apiService/apiConfig'
import Button from '@material-ui/core/Button'
import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { searchLocalServer } from '../../../main/terminal/terminalProvaider'

const useStyles = makeStyles(theme => ({
  loader: {
    left: '45%',
    top: '50%',
    position: 'absolute'
  }
}))

const TerminalConfigForm = ({ close }) => {
  const [terminalHostApi, setTerminalHostApi] = useState(null)
  const history = useHistory()
  const classes = useStyles()
  const serviceName = 'settings'
  const api = apiStringify(serviceName)

  useEffect(() => {
    getDeviceUserKeys()
  })

  async function getDeviceUserKeys() {
    const deviceInfo = await apiService.call({ serviceName, action: api.device })
    const url = await searchLocalServer(deviceInfo.terminal_id, deviceInfo.user_key)
    setTerminalHostApi(url)
  }

  function startTerminalMode() {
    history.push({
      pathname: '/terminal',
      state: { terminalHostApi }
    })
  }

  return (
    <div className="w-full">
      {!terminalHostApi && (
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="w-full mx-auto mt-16 normal-case "
        aria-label="REGISTER"
        disabled={!terminalHostApi}
        value="legacy"
        onClick={() => {
          startTerminalMode()
        }}
      >
        Start Terminal Mode
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="w-full mx-auto mt-16 normal-case "
        aria-label="REGISTER"
        value="legacy"
        onClick={() => {
          close()
        }}
      >
        Cancel
      </Button>
    </div>
  )
}

export default TerminalConfigForm
