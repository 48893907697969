import React from 'react'
import { useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ThemeProvider } from '@material-ui/core/styles'

function FooterLayout1(props) {
  const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme)

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-10"
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.default }}
      >
        <Toolbar className="px-16 py-0 flex items-center">
          <div className="flex flex-1">&copy; FAK Next 2020</div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default FooterLayout1
