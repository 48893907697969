const apiUrl = process.env.REACT_APP_API_URL
const apiPort = process.env.REACT_APP_API_PORT

const jwtServiceConfig = {
  url: () => `${apiUrl}:${apiPort}/api/v1/`,
  oauth2: {
    authorize: () => 'oauth2/authorize'
  }
}

export default jwtServiceConfig
