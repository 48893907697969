import apiService from 'app/services/apiService'
import { apiStringify } from 'app/services/apiService/apiConfig'

export const SET_FAK_SETTINGS = '[SETTINGS] SET FAK SETTINGS'
export const SET_FAK_USER_SETTINGS = '[SETTINGS] SET FAK USER SETTINGS'

const serviceName = 'settings'
const api = apiStringify(serviceName)

export function getSettings() {
  return async dispatch => {
    try {
      const settings = await apiService.call({ serviceName, action: api.get })

      dispatch({
        type: SET_FAK_SETTINGS,
        payload: settings
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
}

export function getUserSettings() {
  return async dispatch => {
    try {
      const user = await apiService.call({ serviceName, action: api.user })

      dispatch({
        type: SET_FAK_USER_SETTINGS,
        payload: user
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
}
