import axios from 'axios'

export const searchLocalServer = async (deviceKey, userKey) => {
  let result = ''
  const ipserver = `https://server.myfak.online/master_lookup.php?callback=?&userKey=${userKey}&deviceKey=${deviceKey}`
  const proxyurl = 'https://cors-anywhere.herokuapp.com/'
  try {
    const serverHostIP = await axios
      .post(proxyurl + ipserver)
      .then(response => response.data && JSON.parse(response.data.substring(2, response.data.length - 1)).ip)
    result = `http://${serverHostIP}/boot/boot_client.php?userKey=${userKey}&deviceKey=${deviceKey}`
  } catch (e) {
    console.log(e)
  }
  return result
}

export default searchLocalServer
