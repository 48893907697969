import clsx from 'clsx'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Card from '@material-ui/core/Card'
import FuseAnimate from '@fuse/core/FuseAnimate'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import { darken } from '@material-ui/core/styles/colorManipulator'

import JWTLogin from './widgets/JWTLogin'
import PinCodeLogin from './widgets/PinCodeLogin'

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText
  }
}))

function Login() {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)

  function handleTabChange(event, value) {
    setSelectedTab(value)
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0')}>
      <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
        <FuseAnimate animation="transition.expandIn">
          <img className="w-128 mb-32" src="assets/images/logos/fak.svg" alt="logo" />
        </FuseAnimate>

        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="h3" color="inherit" className="font-light">
            Welcome to the FAK Next App!
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={400}>
          <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
            Your next software.
          </Typography>
        </FuseAnimate>
      </div>

      <FuseAnimate animation={{ translateX: [0, '100%'] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <Typography variant="h6" className="text-center md:w-full mb-48">
              LOGIN TO YOUR ACCOUNT
            </Typography>

            <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" className="w-full mb-32">
              <Tab
                icon={<img className="h-40" src="assets/images/logos/pincode.svg" alt="pincode" />}
                className="min-w-0"
                label="Pincode"
              />
              <Tab
                icon={<img className="h-40 p-4 bg-black rounded-12" src="assets/images/logos/password.png" alt="jwt" />}
                className="min-w-0"
                label="Password"
              />
            </Tabs>

            {selectedTab === 0 && <PinCodeLogin />}
            {selectedTab === 1 && <JWTLogin />}

            <div className="flex flex-col items-center justify-center pt-32">
              <span className="font-medium">Don't have an account?</span>
              <Link className="font-medium" to="/register">
                Create an account
              </Link>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  )
}

export default Login
