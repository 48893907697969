import FuseScrollbars from '@fuse/core/FuseScrollbars'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import withReducer from 'app/store/withReducer'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import * as Actions from './store/actions/index'
import reducer from './store/reducers'
import TerminalConfigForm from './TerminalConfigForm'

const useStyles = makeStyles(theme => ({
  root: {
    width: 300
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  }
}))

function QuickPanel(props) {
  const dispatch = useDispatch()
  const state = useSelector(({ quickPanel }) => quickPanel.state)

  const classes = useStyles()
  const [checked, setChecked] = useState('notifications')
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  useEffect(() => {
    dispatch(Actions.getQuickPanelData())
  }, [dispatch])

  return (
    <Drawer
      classes={{ paper: classes.root }}
      open={state}
      anchor="right"
      onClose={ev => dispatch(Actions.toggleQuickPanel())}
    >
      <FuseScrollbars>
        <ListSubheader component="div">Today</ListSubheader>

        <div className="mb-0 py-16 px-24">
          <Typography className="mb-12 text-32" color="textSecondary">
            {moment().format('dddd')}
          </Typography>
          <div className="flex">
            <Typography className="leading-none text-32" color="textSecondary">
              {moment().format('DD')}
            </Typography>
            <Typography className="leading-none text-16" color="textSecondary">
              th
            </Typography>
            <Typography className="leading-none text-32" color="textSecondary">
              {moment().format('MMMM')}
            </Typography>
          </div>
        </div>
        <Divider />
        <List>
          <ListSubheader component="div">Quick Settings</ListSubheader>
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon className="m-12">notifications</Icon>
            </ListItemIcon>
            <ListItemText primary="Notifications" />
            <ListItemSecondaryAction>
              <Switch
                color="secondary"
                onChange={handleToggle('notifications')}
                checked={checked.indexOf('notifications') !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon className="min-w-40">
              <IconButton
                aria-label="open terminal"
                component="span"
                onClick={() => {
                  handleOpen()
                }}
              >
                <Icon>airplay</Icon>
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Terminal" />
          </ListItem>
          {open && <TerminalConfigForm close={handleClose} />}
        </List>
      </FuseScrollbars>
    </Drawer>
  )
}

export default withReducer('quickPanel', reducer)(QuickPanel)
