import React from 'react'
import { Redirect } from 'react-router-dom'

const ECommerceAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/apps/e-commerce/products/:productId',
      component: React.lazy(() => import('./product/Product'))
    },
    {
      path: '/apps/e-commerce/products',
      component: React.lazy(() => import('./products/Products'))
    },
    {
      path: '/apps/e-commerce/product-groups',
      component: React.lazy(() => import('./product-groups/ProductGroups'))
    },
    {
      path: '/apps/e-commerce/customers/:customerId',
      component: React.lazy(() => import('./customer/Customer'))
    },
    {
      path: '/apps/e-commerce/customers',
      component: React.lazy(() => import('./customers/Customers'))
    },
    {
      path: '/apps/e-commerce/orders/:orderId',
      component: React.lazy(() => import('./order/Order'))
    },
    {
      path: '/apps/e-commerce/orders',
      component: React.lazy(() => import('./orders/Orders'))
    },
    {
      path: '/apps/e-commerce',
      component: () => <Redirect to="/apps/e-commerce/products" />
    }
  ]
}

export default ECommerceAppConfig
