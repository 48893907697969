import FuseUtils from '@fuse/utils/FuseUtils'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import pincodeServiceConfig from './pinCodeServiceConfig'
/* eslint-disable camelcase */

class PinCodeService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors()
  }

  // @TODO get real profile from backed part, for now it is fake user data
  getUser = () => {
    return new Promise((resolve, reject) => {
      resolve({
        uuid: 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1',
        from: 'custom-db',
        role: 'admin',
        data: {
          displayName: 'Andrew Shapovalov',
          photoURL: 'assets/images/avatars/AndrewShapovalov.gif',
          email: 'admin',
          settings: {
            layout: {
              style: 'layout1',
              config: {
                scroll: 'content',
                navbar: { display: true, folded: true, position: 'left' },

                toolbar: {
                  display: true,
                  style: 'fixed',
                  position: 'below'
                },

                footer: {
                  display: true,
                  style: 'fixed',
                  position: 'below'
                },

                mode: 'fullwidth'
              }
            },
            customScrollbars: true,
            theme: { main: 'defaultDark', navbar: 'defaultDark', toolbar: 'defaultDark', footer: 'defaultDark' }
          },

          shortcuts: ['calendar', 'mail', 'contacts']
        }
      })
    })
  }

  signInWithPinCode = pincode => {
    const url = pincodeServiceConfig.url() + pincodeServiceConfig.oauth2.pincode()
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          pincode
        })
        .then(response => {
          if (response.data.token) {
            this.setSession(response.data.token)

            resolve(this.getUser())
          } else {
            reject(response.data.error)
          }
        })
    })
  }

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token)
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
    } else {
      localStorage.removeItem('jwt_access_token')
      delete axios.defaults.headers.common.Authorization
    }
  }

  logout = () => {
    this.setSession(null)
  }

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false
    }
    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn('access token expired')
      return false
    }

    return true
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token')
  }
}

const instance = new PinCodeService()

export default instance
