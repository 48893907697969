const settingsConfig = {
  layout: {
    style: 'layout1',
    config: {} // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  animations: true,
  direction: 'ltr', // rtl, ltr
  theme: {
    main: 'defaultDark',
    navbar: 'mainThemeDark',
    toolbar: 'mainThemeLight',
    footer: 'mainThemeDark'
  }
}

export default settingsConfig
